.lf-task-drawer{

}

.lf-task-pages-item{
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
}
.lf-task-pages-item__img{
    background-color: var(--shade-color) !important;
    overflow: hidden;
    border-radius: 6px;
    width: 150px;
    height: 100px;
    min-width: 150px;
    max-height: 100px;
    margin-right: 20px;
}
.lf-task-pages-item .lazy-load-image-background{
    width: 150px;
    height: 100px;
    min-width: 150px;
    max-height: 100px;
}
.lf-task-pages-item .lazy-load-image-background img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: var(--shade-color) !important;
}

.lf-task-pages-item__title{
    padding-bottom: 4px;
}
.lf-task-pages-item__text{
    color: var(--text-1);
}
.lf-task-pages-item__remove{
    width: 32px;
    min-width: 32px;
    height: 32px;
    margin-right: -16px;
    opacity: 0;
    transition: opacity 0.3s ease;
}
.lf-task-pages-item:hover .lf-task-pages-item__remove{
    opacity: 1;
}

.lf-task-icon-picker{
    border-radius: 8px;
    background-color: white;
    width: 400px;
    box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08),
    -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
}




.task-drawer {
  padding: 5px 25px;
}

.task-drawer__header,
.task-drawer__header__right {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.task-drawer__header__right {
  align-items: center;
}

.task-drawer__header p {
  margin: 0;
}

.task-drawer__header__title {
  font-size: 24px;
  font-weight: 700;
}

.task-drawer__body {
  margin: 25px 0;
}

.task-drawer__category,
.task-drawer__body {
  padding: 0 11px;
}

.task-drawer__collapse__item,
.task-drawer__category {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.task-drawer__category {
  align-items: center;
  gap: 15px;
}

.task-drawer__collapse__item p:last-child {
  margin: 0;
  margin-top: 4px;
}

.task-drawer__label {
  color: var(--text-1);
  min-width: 150px;
  margin: 0;
  padding-top: 4px;
}

.task-drawer__add {
  color: var(--text-1);
  border: 1px dotted var(--border-color);
  border-radius: 10px;
  padding: 13px 16px;
  cursor: pointer;
}

.task-drawer .ant-collapse-borderless {
  background-color: #fff;
}

.task-drawer__header__right img {
  cursor: pointer;
}

.task-drawer__category__title {
  cursor: default;
  font-weight: 600;
}

.task-management__avatar-picker-container {
  background-color: white;
  box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08),
    -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  width: 100%;
  /*padding: 10px;*/
  padding: 0;
}

.task-management__avatar-picker__header > p {
  font-weight: 700;
  margin: 0;
}

.task-management__avatar-picker__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.task-management__avatar-picker__header {
  cursor: pointer;
}

.task-management-layout {
  height: calc(100vh - var(--header-height) - var(--side-padding));
  overflow: auto;
  padding: 0 var(--side-padding);
}

.task__page-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  cursor: default;
}

.task__page__image {
  width: 120px;
  border-radius: 10px;
}

.task__page__title {
  font-weight: 700;
  font-size: 16px;
  margin-top: 0;
}

.task__pages__title {
  font-weight: 700;
  margin-top: 50px;
}

.pl11 {
  padding-left: 11px;
}

.task-drawer__collapse__item__right-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.task-drawer__collapse__hint {
  width: 14px;
  border: 1px solid #6c6c8929;
  border-radius: 12px;
  padding: 3px;
}

.task-drawer__options__popup {
  width: 250px;
}

.task-drawer__options__popup p {
  margin: 0;
}
