.lf-chips {
  display: flex;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  padding: 4px 8px;
  border-radius: 6px;
  width: fit-content;
  flex: 0 0 auto;
  line-height: 1;
  cursor: default;
    align-items: center;
}
