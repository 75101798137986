.lf-block{
    /*border-radius: 8px;*/
    margin-bottom: 16px;
    background-color: white;
    --block-side-padding: 32px;
    --block-radius: 8px;
    /*overflow: hidden;*/
    /*border-radius: 8px;*/
    position: relative;
}

.lf-block-section{
    border-bottom: 1px var(--border-color) solid;
    padding-left: var(--block-side-padding);
    padding-right: var(--block-side-padding);
}

.lf-block > div{
    border-left: 1px var(--border-color) solid;
    border-right: 1px var(--border-color) solid;
}
.lf-block > div:first-child{
    /*border-top-left-radius: 8px;*/
    /*border-top-right-radius: 8px;*/
    /*border-top: 1px var(--border-color) solid;*/
}
.lf-block > div:last-child{
    border-bottom-left-radius: var(--block-radius);
    border-bottom-right-radius: var(--block-radius);
    border-bottom: 1px var(--border-color) solid;
}

.lf-block-header{
    display: flex;
    align-items: center;
    padding: 0 var(--block-side-padding);
    border-bottom: 1px var(--border-color) solid;
}
.lf-block-header[data-size='sm']{
    height: 48px;
}
.lf-block-header[data-size='md']{
    height: 56px;
}

.lf-block-tabs{
    border-bottom: 1px solid var(--border-color);
    display: flex;
    flex-direction: row;
    padding: 0 var(--block-side-padding);
}

.lf-block-tabs__item{
    padding: 12px 0;
}

.lf-block__before{
    content: '';
    display: flex;
    width: calc(100% + 2px);
    background-color: transparent;
    z-index: 101;
    position: absolute;
    top:0;
}
.lf-block--fix-thead .lf-block__before{
    position: sticky;
}

.lf-block__before-inside{
    display: flex;
    position: absolute;
    width: 100%;
    height: 7px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: 1px var(--border-color) solid;
    border-left: 1px var(--border-color) solid;
    border-right: 1px var(--border-color) solid;
    left: -1px;
    top:0;
    z-index: 101;
    background-color: transparent;
}
.lf-block__before:before{
    content: '';
    display: flex;
    width: 8px;
    height: 8px;
    background-color: white;
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: -1;
}
.lf-block__before:after{
    content: '';
    display: flex;
    width: 8px;
    height: 8px;
    background-color: white;
    position: absolute;
    top: -1px;
    right: -1px;
    z-index: -1;
}

.lf-block-collapse{
    padding: 0 var(--block-side-padding);
    border-bottom: 1px var(--border-color) solid;
}
.lf-block-collapse__header{
    display: flex;
    align-items: center;
    height: 56px;
    cursor: pointer;
    box-shadow: none;
}

.lf-block-collapse__header > .lf-icon{
    margin-right: 8px;
    transition: transform 0.2s ease-out;
}
.lf-block-collapse--open .lf-block-collapse__header > .lf-icon{
    transform: rotate(90deg);
}
.lf-block-collapse__title{
    flex: 1;
}
.lf-block-collapse__after{
    flex: 0;
    opacity: 0;
}
.lf-block-collapse__header:hover .lf-block-collapse__after{
    opacity: 1;
}

.lf-block-collapse--open .lf-block-collapse__body{
    box-shadow: 0 -1px  0 0 var(--border-color);
}

.lf-block-collapse__body{
    overflow: hidden;
    /*padding: 0 var(--block-side-padding);*/
    /*box-shadow: 0 -1px  0 0 var(--border-color);*/

    transition: height 0.2s ease, opacity 0.2s ease;
    display: block;
    justify-content: flex-start;
    opacity: 0;
    margin-left: 24px;
    /*box-shadow: 0 1px  0 0 var(--border-color);*/
}
.lf-block-collapse__body > div{
    padding-bottom: 4px;
}
.lf-block-collapse--open .lf-block-collapse__body{
    opacity: 1;
}
.lf-block-add {
    border: 1px var(--border-color) dashed;
    border-style: dashed;
    margin-bottom: 16px;
    color: var(--text-2);
    --lf-icon-color: var(--text-2);
    border-radius: 8px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: height 0.2s ease, opacity 0.2s ease 0.1s;

}
.lf-block-add--hide{
    height: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: height 0.2s ease 0.1s, opacity 0.2s ease ;

}

.lf-block-add:hover{
    color: var(--text-1);
    --lf-icon-color: var(--text-1);
}
/*.lf-block-collapse__header:hover{*/
/*    background-color: var(--shade-color);*/
/*}*/
/*.lf-block-header{*/
/*    padding: 0 var(--block-side-padding);*/
/*}*/
/*.lf-block-header[data-size='sm']{*/
/*    height: 48px;*/
/*}*/
/*.lf-block-header[data-size='md']{*/
/*    height: 56px;*/
/*}*/
