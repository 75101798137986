/* Define CSS variables for colors */
:root {
  --background-color: rgb(244, 244, 244);
  --container-background-color: #f5f5f5;
  --border-color: #6c6c8929;
  --question-background-color: #6c6c8929;
  --answer-border-color: #007aff;
  --answer-selected-background-color: #007aff;
  --answer-locked-border-color: #dedede;
  --answer-text-color: #007aff;
  --task-background-color: var(--white-color);
  --task-description-color: rgba(108, 108, 137, 1);
  --submit-btn-background: #007aff;
  --submit-btn-color: #fff;
  --shade-color: var(--shade-color); /* Assuming var(--shade-color) is defined elsewhere */
  --question-color: #000;
  --question-ghost-border-color: #dedede;
  --question-ghost-color: #000;
  --simulator-pages-card-background: var(--white-color);
  --white-color: #fff;
}

.scenario-simulator-layout {
  height: 100%;
  background-color: var(--background-color);
  padding: 27px;
  overflow: hidden;
  border-left: 1px solid var(--border-color);
  position: relative;
}

.scenario-simulator-content-container {
  width: 304px;
  height: 95%;
  box-sizing: border-box;
  background-color: var(--container-background-color);
  margin-top: 16px;
  overflow-y: auto;
  padding: 16px 8px;
  padding-right: 12px;
  border-radius: 10px;
  box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08),
    -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
}

.question-container {
  width: 60%;
  margin-left: 16px;
  margin-bottom: 4px;
}

.question {
  font-size: 16px;
  line-height: 26px;
  color: var(--question-color);
  font-weight: bold;
}

.question-text-container {
  overflow: hidden;
  border-radius: 18px;
  border-color: var(--border-color);
  border-end-start-radius: 2px;
  background-color: var(--question-background-color);
  padding: 6px 14px;
}

.question-container-ghost {
  background: none;
  border: 1px solid var(--question-ghost-border-color);
}

.question-ghost {
  color: var(--question-ghost-color);
}

.answer-container {
  width: fit-content;
  margin-bottom: 8px;
  cursor: pointer;
  background-color: var(--white-color);
  border: 2px solid var(--answer-border-color);
  border-radius: 17px;
  border-end-end-radius: 0;
  padding: 8px 12px;
}

.answer-container--selected {
  border-color: var(--answer-border-color);
  background-color: var(--answer-selected-background-color);
}

.answer-container--selected p {
  color: var(--submit-btn-color);
}

.answer-container--locked {
  border-color: var(--answer-locked-border-color);
}

.answer-container--locked p {
  color: var(--answer-locked-border-color);
}

.answer-text {
  color: var(--answer-text-color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.multiple-choice {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.multiple-choice-answers {
  text-align: right;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 16px;
}

.else-block {
  text-align: right;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 8px;
  width: 95%;
}

.else-block .input-container {
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  padding: 7px 15px 7px 8px;
  border-radius: 14px;
  margin-bottom: 10px;
  width: 100%;
}

.simulator-task-container {
  background-color: var(--task-background-color);
  border-radius: 24px;
  padding: 16px;
  margin-left: 12px;
  margin-bottom: 16px;
  margin-top: 16px;
  border-bottom-left-radius: 0;
}

.simulator-task-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  margin-top: 8px;
}

.simulator-task-description {
  font-size: 14px;
  color: var(--task-description-color);
}

.simulator-pages-carousel {
  display: flex;
  gap: 10px;
  overflow-y: scroll;
  padding-bottom: 8px;
  margin-top: 16px;
  margin-left: 12px;
}

.simulator-pages-card {
  width: 60%;
  background-color: var(--simulator-pages-card-background);
  padding: 8px;
  border-radius: 10px;
}

.simulator-pages-card-image {
  height: 100px;
}

.simulator-pages-card p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
}

.relative-container {
  position: relative;
}

.multiple-choice-submit-box {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid var(--shade-color);
}

.multiple-choice-drawer-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}

.multiple-choice-submit-btn {
  background: var(--submit-btn-background);
  color: var(--submit-btn-color);
  font-weight: 600;
  border-radius: 20px;
  height: 40px;
}

.multiple-choice-submit-btn:hover {
  background: var(--submit-btn-background) !important;
  color: var(--submit-btn-color) !important;
}

.open-text-drawer-input {
  height: 40px;
}

.fitfact-card-box {
  border-radius: 18px 18px 18px 2px;
  padding: 12px;
  border: 1px solid var(--shade-color);
  margin-left: 16px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.fitfact-card-category-text {
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 8px;
}

.fitfact-card-title-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3rem;
  margin-bottom: 8px;
}

.fitfact-card-source-text {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
}

.fitfact-card-link-box {
  padding: 18px 12px;
  padding-bottom: 6px;
  border-top: 1px solid var(--shade-color);
  margin-top: 16px;
  cursor: pointer;
}

.fitfact-card-link-text {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.scenario-simulator-content-loader {
  position: absolute;
  top: 50%;
  left: 45%;
}
