body {
  /*Colors*/
  --bg-color: #fff;
  --bg-layout-color: rgba(247, 245, 242, 1);
  --shade-color: rgb(108, 108, 108, 0.06);
  --border-color: rgba(108, 108, 108, 0.16);
  --text-0: rgba(40, 40, 40, 1);
  --text-1: rgba(40, 40, 40, 0.7);
  --text-2: rgba(40, 40, 40, 0.4);
  --brand: linear-gradient(0deg, #1422a3, #1422a3),
    linear-gradient(0deg, #0141e4, #0141e4);
  --blue: #2762f5;
  --red: rgba(255, 59, 48, 1);
  --green: rgba(54, 179, 126, 1);
  --yellow: rgba(255, 171, 0, 1);
  --violet: rgba(151, 71, 255, 1);

  /*Layout*/
  --header-height: 88px;
  --header-vertical-padding: 24px;
  --side-padding: 40px;
  --icon-size: 48px;
}

h1 {
  font-size: 22px;
  font-weight: 600;
}

h2 {
  font-size: 18px;
  font-weight: 600;
}

h3 {
  font-size: 18px;
  font-weight: 500;
}

h4 {
  font-size: 16px;
  font-weight: 600;
}

h5 {
  font-size: 14px;
  font-weight: 600;
}

/* .infinite-scroll-component {
  padding: 0 var(--side-padding);
} */
/*.lf-content{*/
/*    padding: 0 var(--side-padding);*/
/*}*/

.ant-btn {
  box-shadow: none !important;
}
.ant-btn.ant-btn-default {
  background-color: var(--shade-color);
  border: none;
}
.ant-btn-default:hover {
  background-color: var(--border-color);
  color: var(--text-0) !important;
  border: none;
}
.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn.ant-btn-lg {
  height: auto;
  /*padding: 12px 31.5px;*/
}

.ant-form-item-label {
  font-weight: 600;
  font-size: 14px;
}

.error-msg {
  color: var(--red);
}

.sider-menu-header {
  padding: 14px 18px;
  display: flex;
  align-items: center;
  max-height: calc(var(--header-height) - 1px);
  min-height: calc(var(--header-height) - 1px);
}

.sider-menu-footer {
  position: fixed;
  bottom: 0;
  width: 301px;
}

/* ANT MENU */
.ant-menu {
  border-inline-end: none !important;
  /* border-top: 1px var(--border-color) solid; */
}

/*.ant-menu .ant-menu-item-group {*/
/*  border-top: 1px var(--border-color) solid;*/
/*  padding-bottom: 4px;*/
/*}*/

/*.ant-menu .ant-menu-item-group:first-child {*/
/*  border-top: none;*/
/*}*/

.ant-menu .ant-menu-item-group-title[title=""] {
  padding: 2px 16px;
}

.ant-menu .ant-menu-item-group-title {
  color: var(--text-0) !important;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 20px;
  /*padding: 12px 24px;*/
  padding: 16px 24px 4px;
}

.ant-menu-item {
  color: var(--text-1) !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.ant-menu-item-selected .ant-menu-title-content {
  color: var(--text-1) !important;
}
.ant-menu-item-active {
  color: var(--text-1) !important;
}

.ant-menu-item {
  width: calc(100% - 32px) !important;
  margin-inline: 12px !important;
}

.ant-menu-item:hover {
  background-color: var(--shade-color) !important;
}

.ant-menu-item-selected {
  background-color: var(--shade-color) !important;
  color: var(--text-0) !important;
  width: calc(100% - 32px) !important;
  margin-inline: 12px !important;
}

.ant-menu-item .ant-menu-item-selected .ant-menu-item-only-child {
  margin-left: -10px;
}

.ant-btn.ant-btn-lg {
  min-height: 44px;
  min-width: 44px;
  line-height: 16px;
  font-size: 14px;
}

.menu-search-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.menu-search-item > span {
  margin-right: 15px;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
  /* width: 280px !important; */
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  color: var(--text-0) !important;
  background-color: var(--shade-color);
}

.clickable {
  cursor: pointer;
}

/*.ant-drawer-content-wrapper{*/
/*    padding: 12px;*/
/*    box-shadow: none !important;*/
/*}*/
/*.ant-drawer-content{*/
/*    border-radius: 8px;*/
/*    box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);*/
/*}*/
