@font-face {
  font-family: "Sora";
  src: url("./assets/font/Sora-VariableFont_wght.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Sora", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
