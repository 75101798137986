.lf-block .lf-table{
    border-radius: 0 !important;
    border: none !important;
}

.lf-block .lf-table {
    border-radius: 0 !important;
    border: none !important;
}

.lf-block--fix-thead .lf-table .ant-table-thead{
    position: sticky;
    top: 0;
    z-index: 100;
}

.lf-block .lf-table .ant-table-cell:first-child{
    border-left: 1px var(--border-color) solid;
}
.lf-block .lf-table .ant-table-cell:last-child{
    border-right: 1px var(--border-color) solid;
}

.lf-block .lf-table .ant-table-row:last-child .ant-table-cell{
    border-bottom: 1px var(--border-color) solid;
}
.lf-block .lf-table:last-child .ant-table-row:last-child .ant-table-cell:first-child{
    border-bottom-left-radius: calc(var(--block-radius));
}
.lf-block .lf-table:last-child .ant-table-row:last-child .ant-table-cell:last-child{
    border-bottom-right-radius: var(--block-radius);
}
.lf-block .lf-table:first-child .ant-table-thead .ant-table-cell{
    border-top: 1px var(--border-color) solid;
}
.lf-block .lf-table:first-child .ant-table-thead .ant-table-cell:first-child{
    border-top-left-radius: calc(var(--block-radius));
    &:after{
        content: '';
        position: absolute;
        display: flex;
        top: -1px;
        left: -1px;
        width: 8px;
        height: 8px;
        background-color: white;
        z-index: -1;
    }
}
.lf-block .lf-table:first-child .ant-table-thead .ant-table-cell:last-child{
    border-top-right-radius: var(--block-radius);
    &:after{
        content: '';
        position: absolute;
        display: flex;
        top: -1px;
        right: -1px;
        width: 8px;
        height: 8px;
        background-color: white;
        z-index: -1;
    }
}
.lf-block .lf-table:not(:first-child) .ant-table-thead .ant-table-cell:first-child{
    border-top-left-radius: 0 !important;
}
.lf-block .lf-table:not(:first-child) .ant-table-thead .ant-table-cell:last-child{
    border-top-right-radius: 0 !important;
}

.lf-block .lf-table:last-child .ant-table-placeholder .ant-table-cell{
    border-bottom-left-radius: var(--block-radius);
    border-bottom-right-radius: var(--block-radius);
    border-bottom: 1px var(--border-color) solid;
}


.lf-block--fix-thead .lf-table:first-child{
    border-top: none;
}
.lf-block--fix-thead .lf-table:first-child .ant-table-thead > tr > th{
    border-top: 1px var(--border-color) solid;
}


