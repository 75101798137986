.lf-editor {
  background-color: var(--shade-color);
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  /*margin-left: -4px;*/
  z-index: 2000;
}

.lf-editor [role="toolbar"] {
  box-sizing: border-box;
  background-color: transparent;
  border-bottom: 1px solid var(--border-color);
  border-radius: 0;
}
.lf-editor.lf-editor__readonly [role="textbox"] {
  padding: 0px;
}

.lf-editor [role="textbox"] {
  padding: 18px;
}

.lf-editor__readonly [role="toolbar"] {
  display: none;
}
.lf-editor__readonly {
  background-color: transparent;
  pointer-events: none;
}
.lf-editor p {
  font-family: Sora;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 8px;
}
.lf-editor p {
}
.lf-editor hr {
    margin-top: 16px;
    margin-bottom: 16px;
}
.lf-editor p:last-child {
  margin-bottom: 0;
}
.lf-editor ol,
.lf-editor ul {
  font-family: Sora;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 8px;
  padding-left: 24px;
  list-style-position: outside;
}
.lf-editor li{
    margin-top: 0px;
}

.lf-editor ul{
    list-style-type: disc;
}
.lf-editor ol {
    list-style-type: decimal;
}
.lf-editor h1,
.lf-editor h2,
.lf-editor h3,
.lf-editor h4,
.lf-editor h5,
.lf-editor h6 {
  margin-top: 1em;
  margin-bottom: 8px;
}

.lf-editor button {
  color: black;
  cursor: pointer;
}

.lf-editor [type="submit"] {
  background-color: #0141e4;
  color: #fff;
}

.lf-editor [type="submit"]:hover {
  background-color: #2666f0;
  color: #fff;
}

.lf-editor a {
  color: #1677ff;
}
