.profile_form-container {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 20px;
  width: 600px;
}

.profile_form-item,
.profile_form-container .invite-user__field {
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: 24px;
}

.profile_form-container .invite-user__field {
  margin: 0;
  margin-left: 25px;
}

.profile_form-item p {
  width: 250px;
  margin: 0;
  color: var(--text-1);
}

.subject-layout__left-menu-header {
  display: flex;
  gap: 20px;
  align-items: center;
  margin: 20px;
}

.left-menu-header__back-btn {
  width: 30px !important;
  border: 0;
}

.subject-id {
  font-size: 16px;
  font-weight: 600;
}

.profile_form__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: 5px 15px 5px 25px;
}

.profile_form__header__title {
  font-size: 16px;
  font-weight: 600;
  cursor: default;
}

.profile_form__header__edit-btn {
  cursor: pointer;
}

.profile_form-container .invite-user__field__name {
  width: 650px;
  margin: 0;
}

.profile_form-container .ant-select-selector {
  padding: 0;
}

.profile_form-container .ant-form-item {
  margin: 15px 0;
}

.profile_form-container .ant-form-item:first-child {
  margin-top: -5px;
}

.profile_form-container .ant-form-item:last-child {
  margin-bottom: 20px;
}

.profile_form-container .ant-select-selector {
  padding: 0 !important;
}

.survey__container {
  width: 100% !important;
}

.survey__items-container,
.survey__drawer__form-item,
.surveys__drawer__header {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.survey__item {
  gap: 20px;
}

.survey__item p {
  color: black;
  width: 130px;
  font-size: 32px;
  font-weight: 600;
}

.survey__item .survey__item__description {
  font-size: 13px;
  font-weight: 400;
}

.survey__drawer__form-item {
  margin: 25px;
  gap: 20px;
}

.survey__drawer__form-item p {
  margin: 0;
  font-size: 14px;
}

.survey__drawer__form-item-label {
  width: 300px;
  color: var(--text-1);
}

.surveys__drawer__form-container {
  width: 550px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.surveys__drawer__header__left :first-child {
  margin: 0;
  font-size: 17px;
}

.surveys__drawer__header__left :last-child {
  font-size: 13px;
  color: var(--text-1);
  margin: 0;
}

.surveys__drawer__header__right {
  display: flex;
  align-items: center;
}

.surveys__drawer__header__right img {
  cursor: pointer;
  width: 50px;
}

.surveys__drawer__header {
  justify-content: space-between;
  margin-bottom: 30px;
}

.form-input-number-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-input-number .ant-input-number {
  max-width: 70px;
}

.tasks-table__name-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
}

.task-drawer__header {
  display: flex;
  align-items: flex-start;
}

.task-drawer__header__right {
  display: flex;
  align-items: center;
}

.task-drawer__title {
  font-size: 24px;
  font-weight: 700;
  cursor: default;
}

.task-drawer__description {
  margin-bottom: 20px;
  cursor: default;
}

.task-drawer__history-item-container {
  border: 1px dotted var(--border-color);
  border-radius: 10px;
  padding: 15px 25px;
  margin: 10px 0;
}

.task-drawer__history-item__title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  cursor: default;
}

.task-drawer__history-item__result {
  font-size: 14px;
  color: var(--text-1);
  margin: 0;
  margin-top: 3px;
  cursor: default;
}

.border-bottom-1 {
  border-bottom: 1px dotted var(--border-color);
}

.mb10 {
  margin-bottom: 10px;
}

.w250 {
  width: 250px;
}

.profile-header__btns {
  display: flex;
}

.profile_form-footer {
  display: flex;
  gap: 15px;
}

.upload-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start; /* Вирівнювання до лівого краю */
}

.upload-card {
  width: 277px;
  max-height: 362px;
  min-height: 323px;
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  opacity: 1;
  cursor: pointer;
}

.upload-image {
  width: 245px;
  height: 235px;
  border-radius: 3.73px;
  object-fit: cover;
  object-position: center;
}

.upload-info {
  display: flex;
  align-items: center;
  gap: 10px;
  width: calc(100% - 32px);
  box-sizing: border-box;
}

.upload-icon {
  width: 36px;
  height: 36px;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  flex-shrink: 0;
}

.upload-text {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.upload-caption {
  font-size: 14px;
  font-weight: bold;
  color: #151515;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-created-at {
  font-size: 12px;
  color: #6C6C89;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-modal-container {
  display: flex;
  width: 100%;
  height: 718px;
}

.upload-modal-image-container {
  width: 690px;
  height: 718px;
  background-color: #F3F3F6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0 0 8px;
  padding: 20px;
}

.upload-modal-image-wrapper {
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.upload-modal-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  object-fit: contain;
}

.upload-modal-meta {
  width: 415px;
  height: 718px;
  background-color: #FFFFFF;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 48px;
}

.upload-meta-content {
  width: 279px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.upload-tags {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  padding: 0 16px 16px 16px;
}

.upload-tag {
  background-color: rgba(108, 108, 137, 0.08) !important; /* 8% прозорість */
  color: var(--text-1) !important;
  border: none;
  align-items: center;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px;
  display: flex;
}

.upload-tag-icon {
  width: 12px;
  height: 12px;
  opacity: 0.5;
  margin-right: 4px;
  flex-shrink: 0;
}

.upload-tag span {
  display: inline-block;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.meta-item {
  display: flex;
  flex-direction: column;
}

.meta-title {
  font-size: 14px;
  font-weight: 400;
  color: #6C6C89;
  margin-bottom: 4px;
}

.meta-value {
  font-size: 14px;
  color: #151515;
  font-weight: 400;
}

.upload-modal .ant-modal-content {
  padding: 0 !important;
}

.upload-modal .ant-modal-body {
  padding: 0 !important;
  margin: 0 !important;
}
