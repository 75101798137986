.lf-table .ant-table-thead > tr {
  border: none;
  background-color: white;
}

.lf-table .ant-table-thead > tr > th {
  border-bottom: 1px var(--border-color) solid;
  background: none;
  color: var(--text-2);
  font-weight: 400;
  height: 48px;
  padding-bottom: 12px;
  padding-top: 12px;
}

.lf-table .ant-table-cell:first-child {
  padding-left: var(--block-side-padding);
}
.lf-table .ant-table-cell:last-child {
  padding-right: var(--block-side-padding);
}

.lf-table .ant-table-row:last-child > td {
  border-bottom: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-row {
  animation: fadeIn 0.3s ease-in;
}
