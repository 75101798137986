.lifepoints-content-container {
  padding: 0px 15px 5px 25px;
  display: flex;
  max-height: calc(100vh - 200px);
  overflow-x: auto;
}

.lifepoints-content-item {
  flex: 1;
}

.lifepoints-content-item-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--text-1);
  margin: 0;
  margin-top: 24px;
  cursor: default;
}

.lifepoints-content-item-activity,
.lifepoints-content-item-points {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  margin-top: 24px;
  cursor: default;
}

.lifepoints-content-item-activity {
  color: var(--text-1);
  line-height: 32px;
}

.lifepoints-content-item-points--edit-mode {
  cursor: auto;
}
