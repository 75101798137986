.dropdown-tag-select {
  max-height: 400px;
  overflow: auto;
  box-shadow: 0px 20px 140px 0px rgba(0, 0, 0, 0.15);
}

.dropdown-tag-select > ul > li {
  background-color: inherit !important;
}

.dropdown-tag-select > ul > li {
  cursor: default !important;
}

.dropdown-tag-select-placeholder {
  font-size: 12px;
  margin-left: 10px;
  color: rgba(178, 178, 199, 1);
  cursor: pointer;
}

.dropdown-tag-select-tag-item {
  margin-right: 10px;
  cursor: default;
  font-size: 14px;
  color: var(--text-1);
  border: none;
  background-color: var(--shade-color);
  padding: 2px 5px;
}

.ant-dropdown-menu .ant-dropdown-menu-item-disabled {
  color: #000 !important;
}

.dropdown-tag-select-tag-close {
  margin-left: 5px;
  font-size: 10px;
  cursor: pointer;
  color: rgba(108, 108, 137, 1);
}

.dropdown-tag-select-tag-container,
.dropdown-tag-select-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 0px;
}

.dropdown-tag-select-list-item {
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.dropdown-tag-select-search-container {
  padding: 0 10px;
  color: #000;
}

.dropdown-tag-select-search-divider {
  margin: 0;
  margin-bottom: 10px;
}

.dropdown-tag-select-add-icon {
  font-size: 10px;
  margin: 0 10px 0 5px;
}
