.user-management {
  max-height: 100vh;
  overflow: hidden;
}

.user-management__table {
  cursor: default;
}

.user-management__table__name {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-management__table__name > span {
  font-weight: 600;
}

.user-management__user-role--admin,
.user-management__user-role--user,
.user-management__user-status--invited,
.user-management__user-status--invitedError,
.user-management__user-status--confirmed,
.user-management__user-status--blocked,
.user-management__user-status--inactive {
  border: 1px solid;
  border-radius: 5px;
  padding: 3px 8px;
}

.user-management__user-role--admin {
  color: var(--blue);
  border-color: var(--blue);
}

.user-management__user-role--user {
  color: var(--yellow);
  border-color: var(--yellow);
}

.user-management__user-status--invited {
  color: var(--yellow);
  border-color: var(--yellow);
}

.user-management__user-status--inviteError {
  color: var(--red);
  border-color: var(--red);
}

.user-management__user-status--confirmed {
  color: var(--green);
  border-color: var(--green);
}

.user-management__user-status--blocked {
  color: var(--red);
  border-color: var(--red);
}

.user-management__user-status--inactive {
  color: var(--text-2);
  border-color: var(--text-2);
}

.progress-card__section {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
  overflow-x: auto;
}
.progress-card__container {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding-right: 5px;
  min-width: 213px;
}

.progress-card__title {
  padding: 24px 0 8px 32px;
}
.progress-card__results {
  padding: 16px 0 32px 32px;
}

.progress-card__title {
  border-bottom: 1px solid var(--border-color);
}

.dashboard {
  /*margin: 10px 25px;*/
  height: 100%;
  overflow-x: auto;
}
.dashboard-body {
  padding-right: var(--side-padding);
  padding-left: var(--side-padding);
}

.dashboard-item-container {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 15px;
}

.dashboard-summary-cards {
  display: flex;
  gap: 50px;
  padding: 0 40px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
}

.dashboard-summary-cards p {
  margin-bottom: 8px;
}

.dashboard-summary-cards__border-bottom {
  border-bottom: 3px solid black;
}

.health-data-cards-container {
  margin-bottom: 32px;
  box-sizing: border-box;
}

.health-data-cards-container--trio {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.health-data-cards-container--duo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
}

.health-item-card {
  padding: 18px 24px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  min-width: 290px;
  padding-bottom: 6px;
}

.health-item-card__header,
.health-item-card__subheader {
  border-bottom: 1px solid var(--border-color);
}

.health-item-card__header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 18px;
}

.health-item-card__header > p {
  font-size: 13px;
  margin: 0;
}

.health-item-card__subheader {
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
  gap: 4px;
}

.health-item-card__subheader,
.health-item-card__subheader__sufix {
  font-weight: 600;
}

.health-item-card__subheader {
  font-size: 32px;
}

.health-item-card__subheader__sufix {
  font-size: 11px;
  width: 60px;
  margin-top: 6px;
}

.photo-preview {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
  object-position: center;
  display: block;
}
