.task-drawer__footer {
  display: flex;
  justify-content: space-between;
  justify-content: flex-end;
}

#scenario-editor-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

#scenario-editor-wrapper iframe {
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: none;
}

.scenario-editor {
  position: relative;
}

.scenario-page {
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  position: absolute;
  background: #fff;
  left: 64px;
  right: 0;
  top: 0;
  bottom: 0;
  border-left: 1px solid var(--border-color);
}

.scenario-page-header,
.scenario-page-header-right,
.scenario-page-header-left {
  display: flex;
  align-items: center;
  gap: 5px;
}

.scenario-page-header-left {
  gap: 10px;
}

.scenario-page-header-left-btn {
  height: 40px;
}

.scenario-page-header {
  margin-bottom: 36px;
  margin-top: 12px;
  justify-content: space-between;
}

.scenario-page-name {
  font-family: Sora;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

.scenario-info {
  padding: 20px 18px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 20px;
}

.scenario-info-item-container {
  display: flex;
  align-items: center;
}

.scenario-info-item-title {
  width: 120px;
  color: var(--text-1);
  font-size: 14px;
  cursor: default;
}

.scenario-editor {
  height: calc(100vh - 120px);
}

.ant-modal .ant-modal-footer {
  display: flex !important;
  justify-content: flex-end !important;
}

.ant-btn-primary {
  background-color: #0141e4;
  box-shadow: 0 2px 0 rgba(5, 115, 255, 0.1);
}

.scenario-editor-simulator-container {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0px;
  z-index: 1;
}

.scenario-page-run-btn {
  background-color: #6c6c8914;
}

.scenario-page-edit-btn {
  cursor: pointer !important;
}
