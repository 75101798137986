.card-container {
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid rgba(108, 108, 137, 0.16);
  background-color: rgb(255, 255, 255);
}

.card-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  padding: 12px 16px;
  align-items: center;
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  cursor: default;
  transition: all 0.3s, visibility 0s;
  justify-content: space-between;
  border-bottom: 1px solid rgba(108, 108, 137, 0.16);
}

.card-header-left {
  display: flex;
  align-items: center;
}

.card-title {
  font-family: Sora;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  margin: 0;
}
