.lf-main-navigation{
    display: flex;
    flex-direction: column;
    width: 64px;
    min-width: 64px;
    border-right: 1px solid var(--border-color);
    min-height: 100vh;
    height: 100vh;
    overflow: hidden;
    align-items: center;
}

.lf-main-navigation__header{
    display: flex;
    flex-direction: column;
    width: 56px;
    height: var(--header-height);
    align-items: center;
    justify-content: center;
}
.lf-main-navigation__body{
    display: flex;
    flex-direction: column;
}
.lf-main-navigation__footer {
    display: flex;
    margin-top: auto;
}
.lf-main-navigation__body .ant-btn{
    margin-bottom: 4px;
}

.lf-main-navigation__logo{
    max-width: 44px;
}
