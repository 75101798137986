.article-modal-tags-label {
  color: var(--text-1);
  margin-right: 15px;
  margin-bottom: 3px;
  cursor: default;
}

.article-modal-tags-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 150px;
  margin-right: 10px;
}

.markdown-editor {
}
