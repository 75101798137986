.ant-drawer-content-wrapper {
  padding: 12px;
  box-shadow: none !important;
}
.ant-drawer-content {
  border-radius: 8px;
  box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08),
    -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
}
.lf-drawer .ant-drawer-body {
  padding-top: 0;
  padding-bottom: 0;
}
.lf-drawer {
  padding: 0 0;
}
.lf-drawer .ant-drawer-body {
  padding: 0 80px;
}
.drawer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-height: calc(var(--header-height));
  /*border-bottom: 1px solid var(--border-color);*/
  padding-top: 32px;
  gap: 10px;
}
.drawer-header__titles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px; /* Відступ між заголовком і підзаголовком */
  flex: 1;
}
.drawer-header__title {
  font-size: 1.5rem; /* Розмір основного заголовка */
  font-weight: 600;
  margin: 0; /* Забираємо відступи */
  color: var(--text-color); /* Основний колір тексту */
  line-height: 1.3;
}
.drawer-header__subtitle {
  font-size: 0.875rem; /* Менший розмір для підзаголовка */
  color: var(--text-1); /* Сірий колір */
  margin: 0; /* Забираємо відступи */
  font-weight: 400;
}
.drawer-header__left {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.drawer-header__left > div {
  width: 100%;
}
.drawer-header__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0;
}

.ant-dropdown {
  background-color: transparent;
}

.drawer-header__badge {
  background-color: #EFEFEF; /* Світло-сірий фон */
  color: var(--text-1); /* Темно-сірий текст */
  padding: 4px 12px 4px 8px;
  border-radius: 4px; /* Заокруглені кути */
  font-size: 12px; /* Невеликий розмір тексту */
  display: inline-block; /* Для коректного вирівнювання */
  font-weight: 400; /* Напівжирний текст */
  letter-spacing: 0.1;


}
