.lf-table-cell {
  display: flex;
  align-items: center;
}
.lf-table-cell.no-data .lf-table-cell__text {
  color: var(--text-2);
}
.lf-table-cell__title,
.lf-table-cell__text,
.lf-table-cell__subtext {
  display: block;
  text-overflow-ellipsis: ellipsis;
  overflow: hidden;
  line-height: 1.3;
}
.lf-table-cell__subtext {
  line-height: 1;
  color: var(--text-2);
  font-size: 12px;
  margin-top: 5px;
}
.lf-table-cell__title {
  font-weight: 600;
}
.lf-table-cell__text {
  font-weight: 400;
  color: var(--text-1);
}
.lf-table-cell__img {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  margin-right: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--shade-color);
  margin-left: -6px;
}
.lf-table-cell-chips {
  display: flex;
}
.lf-table-cell-picture {
  width: 100%;
  height: auto;
}
.lf-table-cell-picture__inside {
  position: relative;
}
.lf-table-cell-picture__img {
  border-radius: 6px;
}
