.audit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audit-header__right {
  display: flex;
  align-items: center;
}

.audit-header__right > h1 {
  margin-right: 25px;
}

.analytics-tabs .ant-tabs > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.analytics-tabs .ant-tabs > .ant-tabs-nav {
  margin: 0px !important;
}

.analytics-tabs .ant-tabs {
  border-bottom: none !important;
}

.analytics-tabs .ant-tabs-tab .ant-tabs-tab-btn{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: normal;
}

.analytics-tabs .ant-tabs-tab-active .ant-tabs-tab-btn{
  font-weight: bold;
  color: black !important;
}

.analytics-tabs .ant-tabs-tab-active {
  border: none !important;
}

.analytics-tabs .ant-tabs-ink-bar {
  display: none !important;
}