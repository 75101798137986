.plan-page {
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  width: calc(100% - 50px);
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  position: absolute;
  background: #fff;
  left: 64px;
  z-index: 10;
  /*border-left: 1px solid var(--border-color);*/
}

.plan-page-header,
.plan-page-header-right,
.plan-page-header-left {
  display: flex;
  align-items: center;
  gap: 5px;
}

.plan-page-header-left {
  gap: 10px;
}

.plan-page-header-left-btn {
  height: 40px;
}

.plan-page-header {
  margin-bottom: 36px;
  margin-top: 12px;
  justify-content: space-between;
}

.plan-page-name {
  font-family: Sora;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

.plan-info {
  padding: 20px 18px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  margin-bottom: 20px;
}

.plan-info-item-container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.plan-info-item-container:last-child {
  margin-bottom: 0;
}

.plan-info-item-title {
  width: 120px;
  color: var(--text-1);
  font-size: 14px;
  cursor: default;
}

.calendar {
  padding-top: 20px;
  /*padding-left: 10px;*/
}

.calendar-columns-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 28px auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  /*height: calc(100vh - 370px);*/
  overflow: scroll;
}

.calendar-columns-container .calendar-column,
.calendar-columns-container .calendar-scenario-column {
    padding-left: 6px;
    padding-right: 6px;
}

.calendar-columns-items {
  border-left: 1px solid var(--border-color);
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
}

.calendar-columns-items:first-child {
  border-left: none;
}

.calendar-day {
  color: var(--text-1);
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  cursor: default;
  padding-bottom: 10px;
  padding-left: 10px;
}

.calendar-add-btn {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  /*margin: 0;*/
  color: var(--text-2);
  cursor: pointer;
    margin-top: auto;
  /*margin-top: 4px;*/
  /*margin-bottom: 16px;*/
    padding: 4px 4px;
}
.calendar-add-btn:hover{
    background-color: var(--shade-color);
}

.calendar-add-btn--hidden {
  opacity: 0;
}

.calendar-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: var(--shade-color);
}

.calendar-task-modal-container {
  background-color: #fff;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.calendar-task-modal-header {
  border-bottom: 1px solid var(--border-color);
}

.calendar-task-modal-header,
.calendar-task-modal-header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar-task-modal-header-right {
  justify-content: flex-start;
  gap: 15px;
}

.calendar-task-modal-header-title {
  margin: 0;
  font-weight: 700;
  cursor: default;
}

.calendar-task-modal-footer {
  border-top: 1px solid var(--border-color);
}

.calendar-task-modal-body p {
  margin: 0;
}

.calendar-task-modal-field {
  display: flex;
  align-items: center;
  margin: 16px 0;
}

.calendar-task-modal-body,
.calendar-task-modal-header {
  padding: 8px 18px;
}

.calendar-task-modal-footer {
  padding: 5px 3px;
}

.calendar-task-modal-field-title {
  cursor: default;
  width: 120px;
}

.calendar-task-modal-field-title--disabled {
  color: var(--text-2);
}

.ant-modal .ant-modal-footer {
  display: flex !important;
  justify-content: flex-end !important;
}

.plan-page-loading-box {
  position: relative;
  height: 90vh;
}

.plan-page-loading {
  position: absolute;
  left: 46%;
  top: 50%;
}

.calendar-scenario-column-scroll-box {
  height: 100%;
  overflow-y: scroll;
}

.calendar-item{
    opacity: 1;
    transition: all 0.3s ease;
}

.calendar-item:hover{
    /*opacity: 0.5;*/
    /*transform: scale(1.05);*/
}

/* Стиль для лінії під активною вкладкою */
.plan-page .ant-tabs-ink-bar {
  border: 2px solid #151515 !important;
}

/* Стиль для блоку з Tabs */
.plan-page .ant-tabs-nav {
  height: 54px !important; /* "Hug" висота */
  padding: 0px 24px;
  margin: 0px;
  gap: 8px;
  opacity: 1; /* Перевірте налаштування opacity */
}

.plan-page .ant-tabs-tab,
.plan-page .ant-tabs-tab:hover,
.plan-page .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--text-1);
}
.plan-page .ant-tabs-nav-wrap {
  flex: none !important;
}

.plan-page .ant-tabs-tab-dragging {
  cursor: grabbing;
}

.ant-select-dropdown {
  width: 250px;
}
