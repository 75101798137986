.lf-icon{
    transition: opacity 0.2s ease-in-out;
}
.lf-icon svg path[fill]{
    fill: var(--lf-icon-color, var(--text-0));
}
.lf-icon, .lf-icon svg{
    width: var(--lf-icon-size, 16px);
    height: var(--lf-icon-size, 16px);
}
