.task-drawer {
  padding: 5px 25px;
}

.task-drawer__header,
.task-drawer__header__right {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.task-drawer__header__right {
  align-items: center;
}

.task-drawer__header p {
  margin: 0;
}

.task-drawer__header__title {
  font-size: 24px;
  font-weight: 700;
}

.task-drawer__body {
  margin: 25px 0;
}

.task-drawer__category,
.task-drawer__body {
  padding: 0 11px;
}

.task-drawer__collapse__item,
.task-drawer__category {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.task-drawer__category {
  align-items: center;
  gap: 15px;
}

.task-drawer__collapse__item p:last-child {
  margin: 0;
  margin-top: 4px;
}

.task-drawer__label {
  color: var(--text-1);
  min-width: 150px;
  margin: 0;
  padding-top: 4px;
}

.task-drawer__add {
  color: var(--text-1);
  border: 1px dotted var(--border-color);
  border-radius: 10px;
  padding: 13px 16px;
  cursor: pointer;
}

.task-drawer .ant-collapse-borderless {
  background-color: #fff;
}

.task-drawer__header__right img {
  cursor: pointer;
}

.task-drawer__category__title {
  cursor: default;
  font-weight: 600;
}

.task-management__avatar-picker-container {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  padding-top: 0;
}

.task-management__avatar-picker__header > p {
  font-weight: 700;
  margin: 0;
}

.task-management__avatar-picker__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.task-management__avatar-picker__header {
  cursor: pointer;
}

/* .task-management-layout {
    height: calc(100vh - var(--header-height) - var(--side-padding));
    overflow: auto;
    padding: 0 var(--side-padding);
} */

.task__page-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  cursor: default;
}

.task__page__image {
  width: 120px;
  border-radius: 10px;
}

.task__page__title {
  font-weight: 700;
  font-size: 16px;
  margin-top: 0;
}

.task__pages__title {
  font-weight: 700;
  margin-top: 50px;
}

.pl11 {
  padding-left: 11px;
}

.task-drawer__decription {
  margin-left: -10px;
  font-size: 14px;
}

.categories-drawer__body__title {
  font-size: 14px;
}

.categories-drawer__body__description {
  font-size: 14px;
}

.categories__modal-header__right {
  display: flex;
  align-items: center;
  position: absolute;
  top: -60px;
  right: 22px;
}

.categories-modal {
  margin-top: 40px;
}
