.life-fact-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
}

.life-facts-color-mark {
  width: 16px;
  height: 16px;
  border-radius: 16px;
}
