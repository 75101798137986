.info-card {
  width: 100%;
  max-width: 300px;
  height: 106px;
  padding: 24px 16px 8px 16px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid #6c6c8929;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.info-card--empty {
  background-color: transparent;
  border: none;
  padding: 0;
  height: 106px;
}

.info-card__title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
}

.info-card__subtitle {
  font-size: 12px;
  color: var(--text-1);
  margin: 4px 0 0;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.1px;
  line-height: 16.8px;
}