.lf-content--scroll {
  overflow-y: scroll;
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
  max-height: calc(100vh - var(--header-height));
}

.lf-content {
  padding: 0 var(--side-padding);
}

.lf-content--no-paddings {
  padding-left: 0;
  padding-right: 0;
}
