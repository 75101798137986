.page-content-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 200;
  background-color: #fff;
}

.page-content-loader-box {
  position: absolute;
  top: 200px;
  left: 50%;
}
