.assets-header {
  /* height: var(--header-height); */
  padding: var(--header-vertical-padding) var(--side-padding);
}

.assets-header__wrapper {
  /*border-bottom: 1px solid var(--border-color);*/
  /* height: var(--header-height); */
  width: 100%;
}

.assets-header,
.assets-header__wrapper,
.assets-header__right,
.asset-info__header,
.asset-info__header__asset-type,
.asset-info__form-items-wrapper div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}

.asset-header-search__input,
.asset-header-search__input input {
  background: rgba(243, 243, 246, 1);
}

.assets-header__right {
  justify-content: flex-end;
  flex: 2;
}

.assets-header__search {
  height: 40px;
  background: var(--shade-color);
  margin-right: 10px;
}

.assets-header__search > input {
  background: var(--bg-layout-color);
}

.assets-header__add-btn,
.assets-header__apply-btn,
.assets-header__cancel-btn {
  height: 40px;
}

.assets-header__add-btn {
  margin-right: 20px;
}
.assets-header__add-btn:last-child {
  margin-right: 0;
}

.assets-header__cancel-btn {
  margin-left: 10px;
}

.assets-header__left {
  display: flex;
  align-items: flex-start;
  flex: 4;
}

.assets-header__left > h3,
.assets-header__left > h2 {
  flex-shrink: 0;
  margin-right: 25px;
  line-height: 40px;
}

.assets-header__search .ant-input-prefix,
.assets-header__search .ant-input-prefix .lf-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.assets-header__search .ant-input-prefix {
  margin-right: 8px;
}
.assets-header__search {
  font-size: 14px;
}
