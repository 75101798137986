.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 54px;
  height: 54px;
  background-color: var(--shade-color);
}

.icons-color-selector-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /*margin-bottom: 25px;*/
    padding: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
}
.icon-color-item{
    width: 24px;
    height: 24px;
    border-radius: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-color-item__dot{
    width: 12px;
    height: 12px;
    border-radius: 8px;
}
.icons-catalog {
  display: flex;
  align-items: center;
  gap: 0px;
  flex-wrap: wrap;
    padding: 8px;
    border-top: 1px solid var(--border-color);
}

.w35 {
  width: 35px;
  height: 35px;
}
