.ant-form-item-control-input-content {
  display: flex;
  width: 100%;
}
com-1password-button {
  display: none !important;
}
.lf-form-item .ant-form-item-control-input-content {
  display: flex;
}

.lf-form-item,
.lf-form-item .ant-row.ant-form-item-row,
.lf-form-item .ant-form-item-control-input,
.lf-form-item .lf-form-item__body {
  width: 100%;
}
.ant-form-item-label {
  max-height: 32px !important;
}
.ant-form-item-label label {
  display: flex;
  color: var(--text-1) !important;
  font-weight: 400 !important;
  margin: 0;
  width: var(--label-width, 120px);
  min-height: 32px !important;
  height: 32px !important;
  align-items: center;
}
.ant-form-item-label label:after {
  display: none;
}
.lf-form-element {
  min-height: 30px !important;
  padding: 5px 11px !important;
  border: 1px solid transparent;
  width: 100%;
  justify-content: flex-start;
  cursor: default;
}
.lf-form-element:focus,
.lf-form-element:hover {
  border: 1px solid transparent !important;
  box-shadow: none !important;
}
.lf-form-element.ant-input:hover:not([readonly]),
.lf-form-element.ant-input:focus:not([readonly]) {
  background-color: var(--shade-color);
}
.lf-form-element--edit-mode {
  /*border-bottom: 1px solid var(--border-color);*/
  /*border-bottom-left-radius: 0px;*/
  /*border-bottom-right-radius: 0px;*/
  cursor: text;
}
.lf-form-element--edit-mode:hover {
  /*border-bottom: 1px solid var(--border-color) !important;*/
}
.lf-form-element--edit-mode:focus {
  /*border-bottom: 1px solid var(--border-color) !important;*/
}

.lf-form-element.ant-input-disabled {
  background-color: transparent;
  border: 1px solid transparent;
}

.lf-form-avatar {
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  border-radius: 120px;
  background-color: var(--shade-color);
  margin-left: -8px;
  position: relative;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lf-form-avatar .ant-upload-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}
.lf-form-avatar .avatar-upload-btn {
  width: 120px;
  height: 120px;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lf-form-avatar .ant-avatar {
  width: 120px !important;
  height: 120px !important;
}
.lf-form-avatar .avatar-container img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: center center;
}
.lf-form-avatar .avatar-container .anticon-close {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--text-1);
  background-color: var(--border-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  z-index: 100;
  backdrop-filter: blur(100px);
}
.lf-form-image {
  margin: auto;
  width: 100%;
  height: 369px;
}
.lf-form-image .ant-upload {
  padding: 0 !important;
}
.lf-form-image .ant-upload-drag {
  border: none;
}
.lf-form-image--readonly {
  pointer-events: none;
}
.lf-form-image__inside {
  box-sizing: border-box;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
}
.lazy-load-image-background > img {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.lf-form-image__skeleton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
/* .lf-form-image--has-image {
  padding-bottom: 60%;
} */
.lf-form-image__button-group {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 24px;
  box-sizing: border-box;
  z-index: 1;
  top: 0;
}
.lf-form-image__button-fetch.ant-btn {
  color: white;
  background: black;
  background-image: none;
  backdrop-filter: blur(100px)!important;
}
.lf-form-image--has-image .lf-form-image__button {
  align-items: flex-end;
  justify-content: flex-start;
}
.lf-form-image__button-group .ant-btn {
  backdrop-filter: blur(100px);
}
.lf-form-image__button-remove {
  background-color: rgba(80, 80, 80, 0.2) !important;
}
.lf-form-image__button-remove span {
  color: white;
}
.lf-form-header {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 24px;
  padding-top: 16px;
}
.lf-form-user-status {
  padding: 0 11px;
  display: flex;
  align-items: center;
}
.lf-form-select {
  position: relative;
  width: 100%;
  margin-top: -4px;
}
.lf-form-select .ant-select {
  /* width: auto; */
  height: auto;
}
.lf-form-select:hover .ant-select,
.lf-form-select .ant-select-open {
  width: 100%;
  background-color: var(--shade-color);
  border-radius: 6px;
  height: auto;
}
.lf-form-select--readonly {
  pointer-events: none;
}
.ant-select-item.ant-select-item-option:not(:last-child) {
  margin-bottom: 2px;
}

.lf-form-element-header {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  min-height: 44px !important;
  /*padding-left: 4px !important;*/
  /*padding-right: 4px !important;*/
  /*margin-left: -4px;*/
}

textarea.lf-form-element-header {
  line-height: 1.2 !important;
}

.lf-form-switch {
  padding-left: 12px;
}

.lf-form-switch .ant-switch-inner {
  background-color: var(--border-color);
}

.lf-form-switch .ant-switch:hover {
  background-color: var(--border-color);
}

.lf-form-name {
  color: var(--text-1);
  margin-right: 15px;
  cursor: default;
}

.lf-select-multiline .ant-select-selection-item {
  white-space: pre-wrap;
}

.lf-form-select-search-box {
  height: 32px;
  background-color: rgba(108, 108, 137, 0.08);
  padding: 7px 15px 7px 8px;
  margin: 5px 0px;
  width: fit-content;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}

.lf-form-select-option {
  width: 100%;
  min-height: 32px;
  height: auto;
  margin: 5px 0;
  padding-left: 12px;
  background-color: #fff;
}

.lf-form-select-option--flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.lf-form-select-option:hover {
  background-color: rgba(108, 108, 137, 0.08);
}

.lf-form-select-option--selected {
  background-color: rgba(108, 108, 137, 0.08);
}

.lf-form-select-option-sub-label {
  color: #888;
  font-size: 12px;
  line-height: 1.2;
}

.lf-form-select-option-label {
  line-height: 1.3;
  margin-bottom: 4px;
}

.lf-form-select-search-url--error {
  font-size: 12px;
  color: #ff4d4f;
}

.lf-form-select-option-box {
  max-height: 300px;
  overflow: auto;
}
