.lf-sidebar {
  display: flex;
  flex-direction: column;
  min-width: 256px;
  max-width: 256px;
  --side-bar-padding: 40px;
  max-height: 100vh;
  overflow: auto;
}
.lf-sidebar__header {
  display: flex;
  height: var(--header-height);
  min-height: var(--header-height);
  align-items: center;
  padding-left: var(--side-bar-padding);
  background-color: white;
  position: sticky;
  top: 0;
}
.lf-sidebar__body {
  padding-left: calc(var(--side-bar-padding) - 24px);
}
