.user-activity-status-badge {
  border: 1px solid;
  border-radius: 5px;
  padding: 3px 8px;
  cursor: default;
}

.user-activity-status-badge--invited,
.user-activity-status-badge--invitedError,
.user-activity-status-badge--confirmed,
.user-activity-status-badge--blocked,
.user-activity-status-badge--inactive,
.user-activity-status-badge--PENDING,
.user-activity-status-badge--COMPLETE {
  border: 1px solid;
  border-radius: 5px;
  padding: 3px 8px;
}

.user-activity-status-badge--invited,
.user-activity-status-badge--IN_PROGRESS {
  color: var(--yellow);
  border-color: var(--yellow);
}

.user-activity-status-badge--inviteError,
.user-activity-status-badge--OVERDUE,
.user-activity-status-badge--DISMISSED,
.user-activity-status-badge--blocked {
  color: var(--red);
  border-color: var(--red);
}

.user-activity-status-badge--confirmed,
.user-activity-status-badge--COMPLETE,
.user-activity-status-badge--COMPLITED,
.user-activity-status-badge--COMPLETED {
  color: var(--green);
  border-color: var(--green);
}

.user-activity-status-badge--inactive,
.user-activity-status-badge--PENDING {
  color: var(--text-2);
  border-color: var(--text-2);
}
